/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer,Sector } from 'recharts';


const CustomPieChart: React.FC<{
  data: Array<{
    name: string;
    value: number;
  }>
}> = ({data}) => {
  /*const dataFake = [
    { name: 'Group A', value: 33 },
    { name: 'Group B', value: 25 },
    { name: 'Group C', value: 12 },
    { name: 'Group D', value: 100-33-25-12 },
  ];*/
  const ref = React.useRef(null);
  const [innerRadius, setInnerRadius] = React.useState<number>(50);
  const [outerRadius, setOuterRadius] = React.useState<number>(50);
  
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  React.useEffect(() => {
    if(!ref)
    {
      return;
    }
    const radius = ref.current.clientHeight > ref.current.clientWidth ? ref.current.clientWidth : ref.current.clientHeight;
    setInnerRadius(radius * 0.3)
    setOuterRadius(radius * 0.5)
  }, [ref])
  
  return (
    <div className='w-full h-full overflow-hidden' ref={ref}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie data={data??[]} dataKey="value" cx="50%" cy="50%" innerRadius={innerRadius} outerRadius={outerRadius} fill="#82ca9d">
            {(data??[]).map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomPieChart;
