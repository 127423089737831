/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Button, Intent  } from '@blueprintjs/core';
import axios from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { CartoDataSet } from '../../../server/controller/business-logic';
import { getCookie } from '../../../shared/helpers';
import { Direction } from '../../../shared/interfaces';
import { useApplicationSettingsContext } from '../../contexts/application-settings-provider';
import { useDirectusContext } from '../../contexts/directus-context';

const EnterpriseDirection: React.FC<{
  enterprise: string;
  defaultValue: string | undefined;
  onEdit: (val: string) => void;
}> = ({
  enterprise,
  defaultValue,
  onEdit
}) => {
  return (
    <div className='w-full flex flex-row gap-2'>
      <label
        className="block font-bold w-1/2"
      >
        <div className="flex items-center">
          {enterprise}
        </div>
      </label>
      <input
        className="border rounded w-1/2 p-2"
        defaultValue={defaultValue}
        onChange={(e) => {
          onEdit(e.target.value)
        }}
      />
    </div>
  )
};

const Directions: React.FC = () => {
  const { applicationSettings, loadTables, database, saveDirections } = useDirectusContext();
  //const [colors, setColors] = React.useState<Record<string, string>>({});
  const [match, setMatch] = React.useState<Record<string, string>>({});
  const navigate = useNavigate();

  const { token } = useApplicationSettingsContext();
  
  const [dataSet, setDataSet] = React.useState<CartoDataSet>({
    allActifs:[],
    actifTypes:[],
    contractTypes:[],
    departments:[],
    managerTypes:[],
    clientTypes:[],
    clients:[],
    owners:[],
    assetManagers:[],
    propertyManagers:[],
    status:[],
    entreprisesVF: [],
    poles: []
  });
  const loadDataSet = async () => {
    const { data: loadedData } = await axios.get<CartoDataSet>(`/api/business/carto`,{
      headers: {
        authorization: `Basic ${token??getCookie('lynx')}`
      }
    });
    setDataSet(loadedData);
  };

  React.useEffect(() => {
    loadDataSet()
  }, [])

  React.useEffect(() => { 
    if (!applicationSettings) {
      return;
    }
    loadTables([process.env.AP_DIRECTIONS, applicationSettings.nomDeLaTableDesActifs], true)
  }, [applicationSettings])

  const entreprises = React.useMemo(() => {
    if (!dataSet)
    {
      return [];
    }

    let entrepriseLoaded = dataSet.entreprisesVF.filter(item => item.label !== "N/A");
    entrepriseLoaded = [...new Set(entrepriseLoaded)].sort();
    return(entrepriseLoaded);
  }, [dataSet]);

  React.useEffect(() => {
    if (!database)
    {
      setMatch ({});
      return
    }
    if (!database[process.env.AP_DIRECTIONS])
    {
      setMatch ({});
      return
    }
    
    const directions: Array<Direction> = database[process.env.AP_DIRECTIONS] as Array<any>;

    const result = {};
    directions.forEach(item => result[item.entreprise] = item.direction);
    setMatch (result);
  }, [database]);
  
  return (
    <div className="w-full h-full flex flex-col gap-4 p-4 overflow-auto">
      <div className='flex-1 w-full flex flex-wrap justify-center gap-2 pl-4 pr-4'>
        {
          entreprises.map(enterprise => {
            return <EnterpriseDirection key={enterprise.label} enterprise={enterprise.label} defaultValue={match[enterprise.label]} onEdit={
              (val: string) => {
                const edit = JSON.parse(JSON.stringify(match));
                edit[enterprise.label] = val;
                setMatch(edit)
              }
            }/>
          })
        }
      </div>
      <div className='h-10 flex flex-row items-center justify-end gap-2'>
        <Button text="Sauvegarder" intent={Intent.PRIMARY} onClick={async () => {
          saveDirections(match);
          navigate("/lynx?page=carto");
        }}/>
        <Button text="Annuler" intent={Intent.DANGER} onClick={() => {
          navigate("/lynx?page=carto");
        }}/>
      </div>
      
    </div>
  );
}

export default Directions;

