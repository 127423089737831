/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
import { Icon } from '@blueprintjs/core';
import React from 'react';

interface SectionProperties {
  key: string;
  value: string;
}

interface Section {
  titre: string;
  properties: Array<SectionProperties>;
}

const SectionAccordion: React.FC<{
  section: Section
}> = ({
  section
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  return (
    <div className='w-full flex flex-col gap-2'>
      <div className={`flex flex-row gap-2 items-center font-bold p-2 w-full overflow-ellipsis whitespace-nowrap  ${isOpen ? "bg-gray-300" : "bg-white"}`}>
        {
          isOpen &&
          <Icon icon="chevron-down" onClick={() => {
            setIsOpen(false);
          }}/>
        }
        {
          !isOpen &&
          <Icon icon="chevron-right" onClick={() => {
            setIsOpen(true);
          }}/>
        }
        {section.titre}
      </div>
      {
        isOpen && section.properties.map(prop => {
          return (
            <div key={`${section.titre} - ${prop.key}`} className='p-2 w-full flex flex-row gap-2 border-b-2 border-gray-300 items-center'>
              <div className='w-1/2 overflow-hidden overflow-ellipsis whitespace-nowrap font-bold'>{prop.key}</div>
              <div className='w-1/2 overflow-hidden overflow-ellipsis whitespace-nowrap'>{prop.value}</div>
            </div>
          )
        })
      }
    </div>
  )
}


export default SectionAccordion;
export {SectionProperties, Section}