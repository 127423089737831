/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
import './assets/style.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { ApplicationSettingsContextProvider } from './contexts/application-settings-provider';
import { AssetsContextProvider } from './contexts/assets-context';
import { DirectusContextProvider } from './contexts/directus-context';
import { ProtectedRouteContextProvider } from './contexts/protected-route';
import LandingPage from './front/landing-page';
import Login from './front/login';
import LoginSSO from './front/login-sso';
import Lynx from './front/lynx';

const App: React.FC = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: process.env.USE_SSO === "true" ? <LoginSSO /> :  <Login />,
    },{
      path: "/menu",
      element: <ProtectedRouteContextProvider><LandingPage /></ProtectedRouteContextProvider>,
    },{
      path: "/lynx",
      element: <ProtectedRouteContextProvider><DirectusContextProvider><Lynx /></DirectusContextProvider></ProtectedRouteContextProvider>,
    }
  ]);
  return (
    <main>
      <ApplicationSettingsContextProvider>
        <AssetsContextProvider>
          <RouterProvider router={router} />
        </AssetsContextProvider>
      </ApplicationSettingsContextProvider>
    </main>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('app'),
);
