/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
/* eslint-disable @typescript-eslint/no-explicit-any */

import { DateInput } from '@blueprintjs/datetime';
import axios from 'axios';
import React from 'react';

import { PacomDataSet } from '../../server/controller/business-logic';
import { getCookie } from '../../shared/helpers';
import CustomPieChart from '../components/custom-pie-chart';
import LynxLayout from '../components/lynx-layout';
import { useApplicationSettingsContext } from '../contexts/application-settings-provider';
import { useDirectusContext } from '../contexts/directus-context';

enum DateMode {
  none="Aucun",
  prospectionRange = "Période de prospection",
  sessionRange = "Session de prospection",
  consultationRange = "Période prévisionnelle de sortie de consultation"
}

const Pacom: React.FC<{
  textFilter: string
  setTextFilter: (value :string) => void
}> = ({
  textFilter,
  setTextFilter
}) => {
  //const { applicationSettings, loadTables, displayConfigurations, database, enumConfigurations } = useDirectusContext();
  const {refreshUserSettings} = useApplicationSettingsContext();
  const [dateMode, setDateMode] = React.useState<DateMode>(DateMode.none);
  const [from, setFrom] = React.useState<Date>(new Date(Date.now() - 3 * 30 * 24 * 60 * 60 * 1000));
  const [to, setTo] = React.useState<Date>(new Date(Date.now() + 3 * 30 * 24 * 60 * 60 * 1000));
  const [hasDate, setHasDate] = React.useState<boolean>(false);
  const [currentCount, setCurrentCount] = React.useState<number>(0);
  const [currentDisplayed, setCurrentDisplayed] = React.useState<Array<any>>([]);

  React.useEffect(() => {
    refreshUserSettings();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { token } = useApplicationSettingsContext();
  const { applicationSettings } = useDirectusContext();
  
  const [dataSet, setDataSet] = React.useState<PacomDataSet>({
    tableDisplaySettings:  [],
    filtersDisplaySettings: [],
    enrichissements: [],
    sessions: []
  });
  const loadDataSet = async () => {
    const { data: loadedData } = await axios.get<PacomDataSet>(`/api/business/pacom`,{
      headers: {
        authorization: `Basic ${token??getCookie('lynx')}`
      }
    });
    //console.log("Pacom", loadedData)
    //console.log("Prospection:", loadedData.filtersDisplaySettings.find(item => item.label === "Porteur de l’action commerciale"))
    const filtrePorteur = loadedData.filtersDisplaySettings.find(item => item.label === "Porteur de l’action commerciale");
    if (filtrePorteur) {
      filtrePorteur.aUneOptionVide = true;
    }
    setDataSet(loadedData);
  };

  React.useEffect(() => {
    loadDataSet()
  }, [])


  const enrichissementsTimeFilter = React.useMemo(() => {
    if(dateMode === DateMode.none) {
      return dataSet.enrichissements;
    }
    if (hasDate === false)  {
      return dataSet.enrichissements;
    }
    return dataSet.enrichissements.filter(item => {
      const itemDate = dateMode === DateMode.consultationRange ? new Date(item.source.DatePreVisionnelleDeSortieDeLaConsultation??0) : new Date(item.source.DateDeFinDeProspection??0)
      return itemDate.valueOf() >= from.valueOf() && itemDate.valueOf() <= to.valueOf();
    })
  }, [dateMode, dataSet.enrichissements, from, hasDate, to]);

  const kpi = React.useMemo(() => {
    let count = 0;
    currentDisplayed.forEach(pacom => count += pacom.status === "Incomplet" ? 1 : 0)
    return {
      percentage: (100.0 * ((currentDisplayed.length - count) / (currentDisplayed.length === 0 ? 1 :currentDisplayed.length))).toFixed(0),
      chartData: [
        {
          name: "Complet",
          value: currentDisplayed.length - count
        },
        { name: 'Incomplet', value: count }
      ]}
  }, [currentDisplayed]);

  const customHeader = React.useMemo(() => {
    return (
      <div className='w-full h-56 flex flex-row gap-4'>
        <div className='w-3/5 h-full overflow-hidden flex flex-col bg-white shadow-sm p-4'>
          <label
            htmlFor="datemode"
            className="block text-sm font-bold"
          >
            <div className="flex items-center">
              Mode de filtre temporel
            </div>
          </label>
          <select
            id="datemode"
            name="datemode"
            onChange={(e) => {
              setHasDate(true);
              setDateMode(e.target.value as DateMode);
            }} 
            className="bg-gray-100 mt-1 block w-full py-2 px-3 border border-gray-500 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            {
              Object.values(DateMode).map((candidate, index) => (
                <option
                  key={`datemode-${candidate}-${index}`}
                  value={candidate}
                >
                  {candidate}
                </option>
              ))
            }
          </select>
          <div className='w-full flex flex-row gap-2 overflow-hidden pt-2 items-center '>
            {
              (dateMode === DateMode.consultationRange || dateMode === DateMode.prospectionRange) &&
              <>
                <div className='w-1/2 h-full overflow-hidden flex flex-row gap-2 items-center'>
                <label
                  htmlFor="from"
                  className="block text-sm font-bold "
                >
                  <div className="flex items-center">
                    De:
                  </div>
                </label>
                
                <DateInput
                  formatDate={date => {
                    const options = {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric'
                    };
                    return new Date(date).toLocaleDateString(undefined, options as any);
                  }}
                  onChange={(selectedDate) => {
                    if (!selectedDate) {
                      return;
                    }
                    setFrom(selectedDate);
                    setHasDate(true);
                  }}
                  parseDate={str => new Date(str)}
                  value={from}
                  className='w-full'
                  maxDate={new Date(Date.now() + 10 * 365 * 24 * 60 * 60 * 1000)}
                />
                </div>
                <div className='w-1/2 h-full overflow-hidden flex flex-row gap-2 items-center'>
                  <label
                    htmlFor="to"
                    className="block text-sm font-bold "
                  >
                    <div className="flex items-center">
                      A:
                    </div>
                  </label>
                  
                  <DateInput
                    formatDate={date => {
                      const options = {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric'
                      };
                      return new Date(date).toLocaleDateString(undefined, options as any);
                    }}
                    onChange={(selectedDate) => {
                      if (!selectedDate) {
                        return;
                      }
                      setTo(selectedDate);
                      setHasDate(true);
                    }}
                    parseDate={str => new Date(str)}
                    value={to}
                    className='w-full'
                    maxDate={new Date(Date.now() + 10 * 365 * 24 * 60 * 60 * 1000)}
                  />
                </div>
              </>
            }
            {
              dateMode === DateMode.sessionRange &&
              <>
              <label
                htmlFor="session"
                className="block text-sm font-bold "
              >
                <div className="flex items-center">
                  Session
                </div>
              </label>
              <select
                id="session"
                name="session"
                onChange={(e) => {
                  if (e.target.value === "Aucune") {
                    setHasDate(false);
                    return
                  }
                  const session = dataSet.sessions.find(item => item.teePeeUUID === e.target.value);
                  setFrom(session?.start);
                  setTo(session?.end);
                  setHasDate(true);
                }} 
                className="bg-gray-100 mt-1 block w-full py-2 px-3 border border-gray-500 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option
                  value={"Aucune"}
                >
                  Aucune
                </option>
                {
                  dataSet.sessions.map((candidate, index) => (
                    <option
                      key={`session-${candidate.teePeeUUID}-${index}`}
                      value={candidate.teePeeUUID}
                    >
                      {candidate.name}
                    </option>
                  ))
                }
              </select>
              </>
            }
          </div>
        </div>
        <div className='w-2/5 h-full overflow-hidden flex flex-row gap-2 bg-white shadow-sm p-4'>
          <div className='w-1/2 flex flex-col gap-2 pl-6 pr-6'>
            <div className='w-full font-bold items-center text-center text-lynx-vinci'>{`Nombre d'actifs`}</div>
            <div className='flex-1 font-bold flex items-center justify-center text-5xl text-white bg-lynx-vinci'>{currentCount}</div>
          </div>
          <div className='w-1/2 h-full flex flex-col gap-2'>
            <div className='w-full font-bold items-center text-center text-lynx-vinci'>{`Taux de finalisation`}</div>
            <div className='flex-1 w-full' style={{ position: "relative"}}>
              {
                dataSet.enrichissements.length > 0 &&
                <>
                  <CustomPieChart data={kpi.chartData}/>
                  <div className='w-full h-full flex flex-row items-center justify-center font-bold text-lynx-vinci text-2xl' style={{position: "absolute", top: 0}}>{kpi.percentage} %</div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }, [dateMode, from, to, dataSet.sessions, dataSet.enrichissements.length, currentCount, kpi.chartData, kpi.percentage]);

  const customFilter = React.useMemo(() => {
    return (
      <>
        <label
          htmlFor="datemode"
          className="block text-sm font-bold"
        >
          <div className="flex items-center text-lg font-bold pl-2 pt-2">
            Mode de filtre temporel
          </div>
        </label>
        <select
          id="datemode"
          name="datemode"
          onChange={(e) => {
            setHasDate(true);
            setDateMode(e.target.value as DateMode);
          }} 
          className="bg-gray-100 text-lg mt-1 block w-full py-2 px-3 border border-gray-500 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          {
            Object.values(DateMode).map((candidate, index) => (
              <option
                key={`datemode-${candidate}-${index}`}
                value={candidate}
              >
                {candidate}
              </option>
            ))
          }
        </select>
        <div className='w-full flex flex-row gap-2 overflow-hidden pt-2 items-center '>
          {
            (dateMode === DateMode.consultationRange || dateMode === DateMode.prospectionRange) &&
            <>
              <div className='w-1/2 h-full overflow-hidden flex flex-row gap-2 items-center'>
              <label
                htmlFor="from"
                className="block text-sm font-bold "
              >
                <div className="flex items-center">
                  De:
                </div>
              </label>
              
              <DateInput
                formatDate={date => {
                  const options = {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  };
                  return new Date(date).toLocaleDateString(undefined, options as any);
                }}
                onChange={(selectedDate) => {
                  if (!selectedDate) {
                    return;
                  }
                  setFrom(selectedDate);
                  setHasDate(true);
                }}
                parseDate={str => new Date(str)}
                value={from}
                className='w-full'
                maxDate={new Date(Date.now() + 10 * 365 * 24 * 60 * 60 * 1000)}
              />
              </div>
              <div className='w-1/2 h-full overflow-hidden flex flex-row gap-2 items-center'>
                <label
                  htmlFor="to"
                  className="block text-sm font-bold "
                >
                  <div className="flex items-center">
                    A:
                  </div>
                </label>
                
                <DateInput
                  formatDate={date => {
                    const options = {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric'
                    };
                    return new Date(date).toLocaleDateString(undefined, options as any);
                  }}
                  onChange={(selectedDate) => {
                    if (!selectedDate) {
                      return;
                    }
                    setTo(selectedDate);
                    setHasDate(true);
                  }}
                  parseDate={str => new Date(str)}
                  value={to}
                  className='w-full'
                  maxDate={new Date(Date.now() + 10 * 365 * 24 * 60 * 60 * 1000)}
                />
              </div>
            </>
          }
          {
            dateMode === DateMode.sessionRange &&
            <>
            <label
              htmlFor="session"
              className="block text-sm font-bold "
            >
              <div className="flex items-center">
                Session
              </div>
            </label>
            <select
              id="session"
              name="session"
              onChange={(e) => {
                if (e.target.value === "Aucune") {
                  setHasDate(false);
                  return
                }
                const session = dataSet.sessions.find(item => item.teePeeUUID === e.target.value);
                setFrom(session?.start);
                setTo(session?.end);
                setHasDate(true);
              }} 
              className="bg-gray-100 mt-1 block w-full py-2 px-3 border border-gray-500 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option
                value={"Aucune"}
              >
                Aucune
              </option>
              {
                dataSet.sessions.map((candidate, index) => (
                  <option
                    key={`session-${candidate.teePeeUUID}-${index}`}
                    value={candidate.teePeeUUID}
                  >
                    {candidate.name}
                  </option>
                ))
              }
            </select>
            </>
          }
        </div>
      </>
    );
  }, [dateMode, from, to, dataSet.sessions]);

  const customMobileHeader = React.useMemo(() => {
    return (
      <div className='w-full h-36 flex flex-row gap-4'>
        <div className='w-full h-full overflow-hidden flex flex-row gap-2 bg-white shadow-sm p-4'>
          <div className='w-1/2 flex flex-row gap-2 pl-6 pr-6'>
            <div className='w-1/2 h-full font-bold flex items-center justify-center text-center text-lynx-vinci'>{`Nombre d'actifs`}</div>
            <div className='w-1/2 h-full font-bold flex items-center justify-center text-4xl text-white bg-lynx-vinci'>{currentCount}</div>
          </div>
          <div className='w-1/2 h-full flex flex-row gap-2'>
            <div className='w-1/2 h-full font-bold flex items-center justify-center text-center text-lynx-vinci'>{`Taux de finalisation`}</div>
            <div className='w-1/2 h-full' style={{ position: "relative"}}>
              {
                dataSet.enrichissements.length > 0 &&
                <>
                  <CustomPieChart data={kpi.chartData}/>
                  <div className='w-full h-full flex flex-row items-center justify-center font-bold text-lynx-vinci text-2xl' style={{position: "absolute", top: 0}}>{kpi.percentage} %</div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }, [dataSet.enrichissements.length, currentCount, kpi.chartData, kpi.percentage]);


  return (
    <LynxLayout 
      textFilter={textFilter}
      setTextFilter={setTextFilter}
      tableDisplaySettings={dataSet.tableDisplaySettings}
      filtersDisplaySettings={dataSet.filtersDisplaySettings}
      rawData={enrichissementsTimeFilter}
      teePeeBaseURL={applicationSettings.lienVersLesEnrichissements}
      customHeader={customHeader}
      customCellRenderer={{
        "Priorité commerciale" : (cellValue) => {
          let cellColor = cellValue.includes("1") ? "bg-lynx-high" : "bg-lynx-stroke";
          cellColor = cellValue.includes("2") ? "bg-lynx-medium" : cellColor;
          cellColor = cellValue.includes("3") ? "bg-lynx-low" : cellColor;
          return <div className={`w-full text-white ${cellColor} rounded p-2 flex flex-row items-center justify-center`}>{cellValue}</div>
        }
      }}
      setCurrentCount={setCurrentCount}
      setCurrentDisplayed={setCurrentDisplayed}
      customFilter={customFilter}
      customMobileHeader={customMobileHeader}
    />
  );
}

export default Pacom;