/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/

import { Button, Icon } from '@blueprintjs/core';
import React from 'react';


interface SortMode {
  name: string,
  isAlphabetic: boolean
}

const SortIcon: React.FC<{
  isActive: boolean
  isAlphabetic: boolean
  onClick: () => void
}> = ({
  isActive,
  isAlphabetic,
  onClick
}) => {
  return (
    <Button minimal onClick={onClick}>
      {
        isActive === false &&
        <div className='h-6 w-6 flex items-center justify-center mouse-pointer'>
          <Icon icon="minus" className='' color='white'/>
        </div>
      }
      {
        isActive === true &&
        isAlphabetic === true &&
        <div className='h-6 w-6 flex items-center justify-center mouse-pointer'>
          <Icon icon="chevron-down" color='white'/>
        </div>
      }
      {
        isActive === true &&
        isAlphabetic === false &&
        <div className='h-6 w-6 flex items-center justify-center mouse-pointer'>
          <Icon icon="chevron-up" color='white'/>
        </div>
      }
    </Button>
  )
}

export default SortIcon;
export { SortMode }