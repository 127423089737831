/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
import { Button, Intent  } from '@blueprintjs/core';
import axios from 'axios';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { getCookie } from '../../../shared/helpers';
import { Criticity, JobState, JobStatus, Log } from '../../../shared/interfaces';
import LogProcess from '../../components/log-process';
import { useApplicationSettingsContext } from '../../contexts/application-settings-provider';

const delay = (ms: number) => new Promise((r) => setTimeout(r, ms));

const JobProcess: React.FC<{
  jobName: string
  onQuit: () => void
}> = ({
  jobName,
  onQuit
}) => {
  const { token } = useApplicationSettingsContext();
  const [jobStatus, setJobStatus] = React.useState<JobStatus>(undefined);
  const [logs, setLogs] = React.useState<Array<Log>>([]);
  const [jobUUID, setJobUUID] = React.useState<string>(undefined);

  const getLogs = async (uuid) => {
    if (uuid === undefined) {
      return;
    }
    await delay(2000);
    try{
      const { data } = await axios.get<{
        status: JobStatus
        logs: Array<Log>
      }>(`/api/jobs/status/${uuid}`, {
        headers: {
          authorization: `Basic ${token??getCookie('lynx')}`
        }
      });
      setLogs(data.logs);
      if (data.status) {
        setJobStatus(data.status);
        if (data.status.state !== JobState.Ongoing) {
          setJobUUID(undefined)
        } else {
          getLogs(uuid);
        }
      } else {
        getLogs(uuid);
      }
    } catch(error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (jobUUID === undefined) {
      return undefined;
    }
    getLogs(jobUUID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobUUID]);

  const onCleanupDatabase = async () => {
    const uuid = uuidv4();
    try{
      await axios.post(`/api/jobs/${jobName}`, {
        jobUUID: uuid
      },{
        headers: {
          authorization: `Basic ${token??getCookie('lynx')}`
        }
      });
      setJobUUID(uuid);
      setJobStatus({
        jobUUID: uuid,
        label: "",
        start: new Date(Date.now()).toISOString(),
        end: undefined,
        state: JobState.Ongoing
      });
      setLogs([{processUUID: uuid, message: "Process en cours de démarrage...", criticity: Criticity.INFO, date: new Date(Date.now()).toISOString()}]);
    } catch(error) {
      console.log(error);
    }
  }

  return (
    <div className="w-full h-full flex flex-col gap-4 p-4 bg-white">
      <div className='flex-1 w-full flex flex-wrap justify-center gap-2'>
        <LogProcess jobStatus={jobStatus} logs={logs} />
      </div>
      <div className='h-10 flex flex-row items-center justify-end gap-2'>
        <Button text="Lancer le process" intent={Intent.DANGER} disabled={jobStatus?.state === JobState.Ongoing} onClick={() => {
          onCleanupDatabase();
          /*saveConfig(editedConfig);
          history.push("/");*/
        }}/>
        <Button text="Fermer" intent={Intent.PRIMARY} onClick={() => {
          /* */
          onQuit();
        }}/>
      </div>
      
    </div>
  );
}

export default JobProcess;

