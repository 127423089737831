/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
import { Button, Intent, Spinner } from '@blueprintjs/core';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import React from 'react';
import { AutoSizer, List } from 'react-virtualized';

import { JobState, JobStatus, Log } from '../../../shared/interfaces';

const LogEntry: React.FC<{
  log: Log;
}> = ({
  log
}) => {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  return (
    <div className="w-full flex flex-row gap-2">
      <div className="pr-2 text-blue-800" style={{width: 150}}>{new Date(log.date).toLocaleDateString(undefined, options as any)}</div>
      <div className="flex-1 truncate">{log.message}</div>
    </div>
  );
};

const LogProcess: React.FC<{
  jobStatus: JobStatus;
  logs: Array<Log>;
}>= ({
  jobStatus,
  logs
}) => {

  const reversedLogs = React.useMemo(() => {
    if (!logs)
    {
      return [];
    }
    return [...logs].reverse();
  }, [logs])

  const { minutes, seconds } = React.useMemo(() => {
    let minutes = "N/A";
    let seconds = "N/A";
    if (jobStatus && jobStatus.start && jobStatus.end)
    {
      const duration = new Date(jobStatus.end).valueOf() - new Date(jobStatus.start).valueOf();
      const totalSeconds = duration / 1000;
      const minutesduration = totalSeconds / 60;
      minutes = `${minutesduration.toFixed(0)}`;
      seconds = `${(totalSeconds - (Math.floor(minutesduration) * 60)).toFixed(0)}`;
    }
    return { minutes, seconds } ;
  }, [jobStatus]);

  const downloadLogs = async () => {
    if (!logs || logs.length === 0) {
      return;
    }
    const workbook = new ExcelJS.Workbook();
    const logsWorksheet = workbook.addWorksheet("Logs");
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    
    logsWorksheet.getRow(1).getCell(1).value = "Time";
    logsWorksheet.getRow(1).getCell(2).value = "Message";
    logsWorksheet.getRow(1).getCell(3).value = "Severity";
    logsWorksheet.getRow(1).getCell(4).value = "Timestamp";
    logs.forEach((log, index) => {
      logsWorksheet.getRow(index+2).getCell(1).value = new Date(log.date).toLocaleDateString(undefined, options as any);
      logsWorksheet.getRow(index+2).getCell(2).value = log.message;
      logsWorksheet.getRow(index+2).getCell(3).value = log.criticity;
      logsWorksheet.getRow(index+2).getCell(4).value = log.date;
    });
    
    const xlsData = await workbook.xlsx.writeBuffer();
    const blob = new Blob([xlsData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, `doe.job.xlsx`);
  }

  return (
    <div className="w-full h-full flex flex-col gap-2 overflow-hidden">
      <div className='w-full flex flex-row p-2'>
        <div className='pr-2'>Status: {jobStatus?.state} - {minutes} minutes {seconds} seconds</div>
        {
          jobStatus?.state === JobState.Ongoing &&
          <Spinner intent={Intent.DANGER} size={12}/>
        }
        <div className='pr-2 flex-1 overflow-hidden flex items-end justify-end'>
          <Button intent={Intent.PRIMARY} onClick={downloadLogs}>Download Logs</Button>
        </div>
      </div>
      <div className="w-full flex-1 overflow-hidden p-2">
        <div className="w-full h-full overflow-hidden flex flex-col">
          <AutoSizer>
            {({ height, width }) => (
              <List
                width={width}
                height={height}
                rowCount={reversedLogs?.length}
                rowHeight={28}
                rowRenderer={props => (
                  <div
                    key={props.key}
                    style={props.style}
                    className="w-full flex items-center pr-1 hover:bg-metal rounded"
                  >
                    <LogEntry
                      log={reversedLogs && reversedLogs[props.index]}
                    />
                  </div>
                )}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    </div>
  )
}

export default LogProcess;