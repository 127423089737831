/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
import { MenuItem } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import axios from 'axios';
import React from 'react';

import { Feature } from '../../../shared/interfaces';

interface Suggestion {
  place_id: string;
  display_name: string;
  lon: number;
  lat: number;
}

const AddressSuggest: React.FC<{
  lat: number;
  lon: number;
  onMove: (lon: number, lat: number) => void;
}> = ({
  lat, lon, onMove
}) => {
  const [query, setQuery] = React.useState<string>("");
  const [isSearching, setIsSearching] = React.useState<boolean>(false);
  const [items, setItems] = React.useState<Array<Suggestion>>([]);

  const handleQueryChange = React.useCallback(
    async (query: string) => {
      setIsSearching(true);
      const address = encodeURIComponent(query);
      try {
        const {data: searchResults} = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?proximity=${lon},${lat}&access_token=${process.env.MAPBOX_TOKEN}`);
        const suggestions: Array<Suggestion> = [];
        searchResults.features.forEach(feature => {
          if (feature.id.startsWith("address")) {
            suggestions.push({
              place_id: feature.id,
              display_name: feature.place_name,
              lon: feature.center[0],
              lat: feature.center[1]
            })
          }
        })
        setItems(suggestions);
      } catch (error) {
        console.log(error);
      }
      setIsSearching(false);
    }, [lat, lon]
  );

  const itemRenderer = React.useCallback(
    (item, { handleClick, modifiers }) => (
      <MenuItem
        key={item.place_id}
        text={item.display_name}
        active={modifiers.active}
        disabled={modifiers.disabled}
        onClick={handleClick}
        multiline
      />
    ),
    []
  );

  const inputValueRenderer = React.useCallback(item => item.display_name, []);

  const handleItemSelect = React.useCallback(
    (item: Suggestion) => {
      onMove(item.lon, item.lat);
    },
    []
  );

  const noResults = React.useMemo(() => (!!query && !isSearching ? <MenuItem disabled text="Pas de résultat." /> : null), [
    query,
    isSearching
  ]);

  return (
    <Suggest
      fill
      query={query}
      onQueryChange={handleQueryChange}
      items={items}
      itemRenderer={itemRenderer}
      inputValueRenderer={inputValueRenderer}
      onItemSelect={handleItemSelect}
      noResults={noResults}
      initialContent="Chercher une adresse..."
    />
  );
};

export default AddressSuggest;
