/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
import axios from 'axios';
import React from 'react';

import { ApplicationSettings, DisplayConfig, EnumConfig, EnumerationsConfigMap, TeePeeContent } from '../../shared/directus';
import { getCookie } from '../../shared/helpers';
import { AssetColor } from '../../shared/interfaces';
import PageLoader from '../components/page-loader';
import { useApplicationSettingsContext } from './application-settings-provider';

interface DirectusContextProps {
  applicationSettings: ApplicationSettings;
  database: Record<string, Array<TeePeeContent>>;
  loadTables: (tableNames: Array<string>, lazy: boolean) => Promise<void>;
  enumConfigurations: EnumerationsConfigMap;
  displayConfigurations: Record<string, Array<DisplayConfig>>;
  saveColors: (colors: Record<string, string>) => Promise<void>;
  getColors: Record<string, string>;
  saveDirections: (colors: Record<string, string>) => Promise<void>;
  loadingData: boolean;
}

const DirectusContext: React.Context<DirectusContextProps> =
  React.createContext<DirectusContextProps>(null);

const DirectusContextProvider: React.FC = props => {
  const { applicationSettings, token } = useApplicationSettingsContext();
  const [database, setDatabase] = React.useState<Record<string, Array<TeePeeContent>>>({});
  const [loading, setLoading] = React.useState<boolean>(true);
  const [loadingData, setLoadingData] = React.useState<boolean>(true);
  const [enumConfigurations, setEnumConfigurations] = React.useState<EnumerationsConfigMap>({});
  const [displayConfigurations, setDisplayConfigurations] = React.useState<Record<string, Array<DisplayConfig>>>({});
  const [getColors, setColors] = React.useState<Record<string, string>>({});

  async function loadSettings() {
    setLoading(true);

    const { data: flatEnumConfig } = await axios.get<Array<EnumConfig>>(`/api/directus/collection/${process.env.AP_ENUMERATIONS}`,{
      headers: {
        authorization: `Basic ${token??getCookie('lynx')}`
      }
    });

    const mappedConfig: Record<string, Record<string, Record<string, string>>> = {}
    flatEnumConfig.forEach(config => {
      if (!mappedConfig[config.nomDeLaTable])
      {
        mappedConfig[config.nomDeLaTable] = {}
      }
      if (!mappedConfig[config.nomDeLaTable][config.nomDuChamp])
      {
        mappedConfig[config.nomDeLaTable][config.nomDuChamp] = {}
      }
      mappedConfig[config.nomDeLaTable][config.nomDuChamp][config.valeur] = config.label;
    })
    setEnumConfigurations(mappedConfig);
    console.log("Loaded enum configurations:", mappedConfig);

    const { data: flatDisplayConfig } = await axios.get<Array<DisplayConfig>>(`/api/directus/collection/${process.env.AP_CONFIGURATION_AFFICHAGE}`,{
      headers: {
        authorization: `Basic ${token??getCookie('lynx')}`
      }
    });
    const mappedDisplayConfig: Record<string, Array<DisplayConfig>> = {}
    flatDisplayConfig.forEach(config => {
      if (!mappedDisplayConfig[config.nomDeLAffichage])
      {
        mappedDisplayConfig[config.nomDeLAffichage] = []
      }
      mappedDisplayConfig[config.nomDeLAffichage].push(config);
    });
    setDisplayConfigurations(mappedDisplayConfig);
    console.log("Loaded display configurations:", mappedDisplayConfig);

    const { data: colors } = await axios.get<Array<AssetColor>>(`/api/directus/collection/${process.env.AP_COLORS}`,{
      headers: {
        authorization: `Basic ${token??getCookie('lynx')}`
      }
    });
    console.log("Loaded colors", colors)
    const map = {}
    colors.forEach(c => map[c.name] = c.color);
    setColors(map);
    setLoading(false);
  }

  React.useEffect(() => {
    loadSettings();
  }, []);

  const loadTables = async (tableNames: Array<string>, lazy: boolean ) => {
    setLoadingData(true);
    const update = {};
    Object.keys(database).forEach(key => update[key] = database[key]);

    for await(const tableName of tableNames)
    {
      if (lazy && database[tableName])
      {
        continue;
      }
      console.log(`Loading TeePee Table ${tableName}...`)
      const { data: content } = await axios.get<Array<TeePeeContent>>(`/api/directus/collection/${tableName}`,{
        headers: {
          authorization: `Basic ${token??getCookie('lynx')}`
        }
      });
      update[tableName] = content;
    }
    setDatabase(update);
    setLoadingData(false);
  }

  const saveColors = async (colors: Record<string, string> ) => {
    const newColors = JSON.parse(JSON.stringify(getColors));
    Object.keys(colors).forEach(candidate => {
      newColors[candidate] = colors[candidate];
    })
    await axios.post(`/api/application/colors`, newColors, {
      headers: {
        authorization: `Basic ${token??getCookie('lynx')}`
      }
    });
    setColors(newColors);
  }

  const saveDirections = async (directions: Record<string, string> ) => {
    await axios.post(`/api/application/directions`, directions, {
      headers: {
        authorization: `Basic ${token??getCookie('lynx')}`
      }
    });
  }

  return (
    <DirectusContext.Provider
      value={{
        applicationSettings,
        database,
        loadTables,
        enumConfigurations,
        displayConfigurations,
        saveColors,
        saveDirections,
        getColors,
        loadingData
      }}
      {...props}
    >
      {loading === true && <PageLoader />}
      {loading === false && props.children}
    </DirectusContext.Provider>
  );
};

const useDirectusContext = (): DirectusContextProps => {
  const context = React.useContext(DirectusContext);
  if (context === undefined) {
    throw new Error(
      `useDirectusContext must be used within a DirectusContextProvider`,
    );
  }
  return context;
};

export { DirectusContextProvider, useDirectusContext };
