/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
import React from 'react';

const Input: React.FC<{
  label: string;
  placeHolder: string
  seletected: string
  onSelected: (selected: string) => void
}> = ({
  label,
  placeHolder,
  seletected,
  onSelected
}) => {
  return (
    <div className='w-full flex flex-col gap-2'>
      <label
        className="block text-sm font-bold"
      >
        <div className="flex items-center">
          {label}
        </div>
      </label>
      <input
        className="w-full border rounded text-sm p-2"
        placeholder={placeHolder}
        value={seletected}
        onChange={(e) => {
          onSelected(e.target.value)
        }}
      />
    </div>
  )
}

export default Input;