/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/

import axios from 'axios';
import React from 'react';

import { TaguageDataSet } from '../../server/controller/business-logic';
import { getCookie } from '../../shared/helpers';
import LynxLayout from '../components/lynx-layout';
import { useApplicationSettingsContext } from '../contexts/application-settings-provider';
import { useDirectusContext } from '../contexts/directus-context';

const Taguage: React.FC<{
  textFilter: string
  setTextFilter: (value :string) => void
}> = ({
  textFilter,
  setTextFilter
}) => {
  const { token, refreshUserSettings } = useApplicationSettingsContext();
  const { applicationSettings } = useDirectusContext();

  React.useEffect(() => {
    refreshUserSettings();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const [dataSet, setDataSet] = React.useState<TaguageDataSet>({
    tableDisplaySettings:  [],
    filtersDisplaySettings: [],
    appariements: []
  });
  const loadDataSet = async () => {
    const { data: loadedData } = await axios.get<TaguageDataSet>(`/api/business/taguage`,{
      headers: {
        authorization: `Basic ${token??getCookie('lynx')}`
      }
    });
    setDataSet(loadedData);
  };

  React.useEffect(() => {
    loadDataSet()
  }, [])

  return (
    <LynxLayout 
      textFilter={textFilter}
      setTextFilter={setTextFilter}
      tableDisplaySettings={dataSet.tableDisplaySettings}
      filtersDisplaySettings={dataSet.filtersDisplaySettings}
      rawData={dataSet.appariements}
      teePeeBaseURL={applicationSettings.lienVersLesAppariements}
      customCellRenderer={{
        "Statut de la demande" : (cellValue) => {
          let cellColor = cellValue.includes("flit") ? "bg-lynx-high" : "bg-lynx-low";
          cellColor = cellValue.includes("nnul") ? "bg-yellow-500" : cellColor;
          cellColor = cellValue.includes("agua") ? "bg-blue-800" : cellColor;
          return <div className={`w-full text-white ${cellColor} rounded p-2 flex flex-row items-center justify-center font-bold`}>{cellValue}</div>
        }
      }}
      />
  );
}

export default Taguage;