/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
import React from 'react';

const KPI: React.FC<{
  label: string;
  value: string;
  size?: string
}> = ({
  label,
  value,
  size
}) => {
  return (
    <div className='bg-white w-full h-full flex flex-col gap-2 items-center justify-center shadow-xl p-4'>
      <div className='text-lynx-vinci overflow-hidden whitespace-nowrap overflow-ellipsis'>{label}</div>
      <div className={`${size ? size : "text-3xl"} font-bold text-lynx-vinci overflow-hidden whitespace-nowrap overflow-ellipsis`}>{value}</div>
    </div>
  )
}

export default KPI;