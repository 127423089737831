/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
import 'mapbox-gl/dist/mapbox-gl.css';

import { Button,  FormGroup,  InputGroup,  Intent } from '@blueprintjs/core';
import React from 'react';
import { useNavigate  } from 'react-router-dom';

import { useApplicationSettingsContext } from '../contexts/application-settings-provider';

const Input: React.FC<{
  label: string;
  placeHolder: string
  seletected: any
  onSelected: (selected: any) => void,
  type: string
}> = ({
  label,
  placeHolder,
  seletected,
  onSelected,
  type
}) => {
  return (
    <div className='w-full flex flex-col gap-2'>
      <label
        className="block text-sm font-bold"
      >
        <div className="flex items-center">
          {label}
        </div>
      </label>
      <input
        className="w-full border rounded text-sm p-2"
        placeholder={placeHolder}
        value={seletected}
        onChange={(e) => {
          onSelected(e.target.value)
        }}
        type={type}
      />
    </div>
  )
}

const LoginSSO: React.FC = () => {  
  return (
    <div className="w-full h-full flex flex-col gap-2 sm:p-4 md:p-12 items-center justify-center">
      <div className='absolute p-4' style={{
        bottom: 0,
        right: 0
      }}>
        Version {process.env.VERSION}
      </div>
      <div className='w-full h-full lynx-background overflow-hidden'>
        <div className='w-full h-full flex flex-col gap-2 items-center justify-center lynx-backdrop-filter overflow-hidden'>
          <div className='w-1/2 h-1/2 flex flex-row gap-4 items-center justify-center' style={{
            minHeight: "320px",
            minWidth: "700px"
          }}>
            <div className='h-full flex flex-col gap-2 '>
              <img className="block max-w-full max-h-full w-auto h-auto" src="/assets/img/logos/lynx.png" />
              <div className='w-full p-2 bg-white'>
                <div className='w-full flex-1 p-2 flex flex-row items-center justify-center'>
                  <button className={"hover:opacity-90 bg-lynx-info w-full h-16 text-xl rounded-xl font-bold flex flex-row items-center justify-center"} onClick={async () => {
                    window.location = "/login" as undefined
                  }}>
                    Se connecter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginSSO;