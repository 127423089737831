/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
import { Button, Intent } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import axios from 'axios';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import React from 'react';

import { getCookie } from '../../../shared/helpers';
import { MonitoringCategories, MonitoringEvent } from '../../../shared/interfaces';
import { useApplicationSettingsContext } from '../../contexts/application-settings-provider';

interface LogEvent {
  date: string;
  category: MonitoringCategories;
  event: MonitoringEvent;
  user: string;
  message: string;
  metadata: string;
}

interface LastAccess {
  date: string;
  email: string;
}

const Logs: React.FC = () => {
  const { token } = useApplicationSettingsContext();
  const [from, setFrom] = React.useState<Date>(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
  const [to, setTo] = React.useState<Date>(new Date(Date.now()));
  const [, setHasDate] = React.useState<boolean>(false);
  const [allLogs, setAllLogs] = React.useState<Array<LogEvent>>([]);
  const [advancedMode, setAdvancedMode] = React.useState<boolean>(false);

  const loadLogs = async () => {
    const {data: loadedLogs} = await axios.get(`/api/application/logs`, {
      headers: {
        authorization: `Basic ${token??getCookie('lynx')}`
      }
    });
    setAllLogs(loadedLogs);
  };

  React.useEffect(() => {
    loadLogs();
  }, []);

  const logs: Array<LogEvent> = React.useMemo(() => {
    console.log(from)
    console.log(to)
    return allLogs.filter(item => {
      const timestamp = new Date(item.date).valueOf();
      return timestamp >= from.valueOf() && timestamp <= to.valueOf();
    }).sort((a,b) => b.date.localeCompare(a.date));
  }, [allLogs, from, to]);

  const lastAccess: Array<LastAccess> = React.useMemo(() => {
    const results: Array<LastAccess> = [];
    const map = {};
    logs.filter(item => item.user &&  item.user.trim().length > 0 && item.user !== "SYSTEM").forEach(log => {
      if (!map[log.user.trim().toLocaleLowerCase()]) {
        map[log.user.trim().toLocaleLowerCase()] = log.date;
      } else {
        const current = new Date(log.date).valueOf();
        const record = new Date(map[log.user.trim().toLocaleLowerCase()]).valueOf();
        if (current > record) {
          map[log.user.trim().toLocaleLowerCase()] = log.date;
        }
      }
    });
    Object.keys(map).forEach(email => {
      results.push({
        date: map[email],
        email: email
      });
    });
    return results.sort((a,b) => b.date.localeCompare(a.date));
  }, [logs]);

  const download = async () => {
    if (advancedMode) {
      if (!logs || logs.length === 0) {
        return;
      }
      const workbook = new ExcelJS.Workbook();
      const logsWorksheet = workbook.addWorksheet("Logs");
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      };
      
      logsWorksheet.getRow(1).getCell(1).value = "Date";
      logsWorksheet.getRow(1).getCell(2).value = "Category";
      logsWorksheet.getRow(1).getCell(3).value = "Event";
      logsWorksheet.getRow(1).getCell(4).value = "User";
      logsWorksheet.getRow(1).getCell(5).value = "Message";
      logs.forEach((log, index) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        logsWorksheet.getRow(index+2).getCell(1).value = new Date(log.date).toLocaleDateString(undefined, options as any);
        logsWorksheet.getRow(index+2).getCell(2).value = log.category;
        logsWorksheet.getRow(index+2).getCell(3).value = log.event;
        logsWorksheet.getRow(index+2).getCell(4).value = log.user;
        logsWorksheet.getRow(index+2).getCell(5).value = log.message;
      });
      
      const xlsData = await workbook.xlsx.writeBuffer();
      const blob = new Blob([xlsData], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, `logs.xlsx`);
    } else {
      if (!lastAccess || lastAccess.length === 0) {
        return;
      }
      const workbook = new ExcelJS.Workbook();
      const logsWorksheet = workbook.addWorksheet("Logs");
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      };
      
      logsWorksheet.getRow(1).getCell(1).value = "Dernier Accès";
      logsWorksheet.getRow(1).getCell(2).value = "Email";
      lastAccess.forEach((log, index) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        logsWorksheet.getRow(index+2).getCell(1).value = new Date(log.date).toLocaleDateString(undefined, options as any);
        logsWorksheet.getRow(index+2).getCell(2).value = log.email;
      });
      
      const xlsData = await workbook.xlsx.writeBuffer();
      const blob = new Blob([xlsData], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, `access.xlsx`);
    }
    
  }

  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };

  return (
    <div className="w-full h-full flex flex-col gap-4 p-4 overflow-auto p-4">
      <div className='w-full flex flex-row items-center justify-end gap-2'>
        <div className='flex-1 h-full overflow-hidden flex flex-row gap-2 items-center p-2'>
          <div className="font-bold ">
            De:
          </div>
          <DateInput
            formatDate={date => {
              const options = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              };
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              return new Date(date).toLocaleDateString(undefined, options as any);
            }}
            onChange={(selectedDate) => {
              console.log(selectedDate); 
              if (!selectedDate) {
                return;
              }
              setFrom(selectedDate);
              setHasDate(true);
            }}
            parseDate={str => new Date(str)}
            value={from}
            className='w-full'
          />
        </div>
        <div className='flex-1 h-full overflow-hidden flex flex-row gap-2 items-center p-2'>
          <div className="font-bold ">
            À:
          </div>
          <DateInput
            formatDate={date => {
              const options = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              };
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              return new Date(date).toLocaleDateString(undefined, options as any);
            }}
            onChange={(selectedDate) => {
              console.log(selectedDate); 
              if (!selectedDate) {
                return;
              }
              setTo(selectedDate);
              setHasDate(true);
            }}
            parseDate={str => new Date(str)}
            value={to}
            className='w-full'
          />
        </div>
        <Button className="flex-1" text={advancedMode === false ? "Mode avancée" : "Dernier accès"} intent={Intent.PRIMARY} onClick={() => { setAdvancedMode(!advancedMode) }}/>
        <Button className="flex-1" text={"Téléchargement Excel"} intent={Intent.SUCCESS} onClick={() => { download() }}/>
        <Button className="flex-1" text={"Refresh"} intent={Intent.NONE} onClick={() => { loadLogs() }}/>
      </div>
      <div className='flex-1 overflow-auto pl-4 pr-4'>
        {
          advancedMode === true &&
          <table
            className={`bp3-html-table bp3-html-table-striped bp3-interactive w-full `}
            style={{
              backdropFilter: "brightness(0.8) blur(10px)",
              backgroundColor: "rgba(255,255,255,0.8)"
            }}
          >
            <thead>
              <tr className='bg-lynx-vinci text-white '>
                {
                  [
                    "Date",
                    "Category",
                    "Event",
                    "User",
                    "Message",
                  ].map((label, index) => {
                    return (
                      <th key={`column-${index}`} className='border-l-2 border-white custom-th'>
                        <div className='w-full flex flex-row gap-2 items-center text-white'>
                          {label}
                        </div>
                      </th>
                    )
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                logs.map((item, index) => {
                  return (
                    <tr key={`item-${index}`}>
                      <td key={`cell--${item.user}-${index}-1`} className={`custom-td`}>
                        {new Date(item.date).toLocaleDateString(undefined, options as any)}
                      </td>
                      <td key={`cell--${item.user}-${index}-2`} className={`custom-td`}>
                        {item.category}
                      </td>
                      <td key={`cell--${item.user}-${index}-3`} className={`custom-td`}>
                        {item.event}
                      </td>
                      <td key={`cell--${item.user}-${index}-4`} className={`custom-td`}>
                        {item.user}
                      </td>
                      <td key={`cell--${item.user}-${index}-5`} className={`custom-td`}>
                        {item.message}
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        }
        {
          advancedMode === false &&
          <table
            className={`bp3-html-table bp3-html-table-striped bp3-interactive w-full `}
            style={{
              backdropFilter: "brightness(0.8) blur(10px)",
              backgroundColor: "rgba(255,255,255,0.8)"
            }}
          >
            <thead>
              <tr className='bg-lynx-vinci text-white '>
                {
                  ["Dernier accès", "Email"].map((label, index) => {
                    return (
                      <th key={`column-${index}`} className='border-l-2 border-white custom-th'>
                        <div className='w-full flex flex-row gap-2 items-center text-white'>
                          {label}
                        </div>
                      </th>
                    )
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                lastAccess.map((item, index) => {
                  return (
                    <tr key={`item-${index}`}>
                      <td key={`cell--${item.email}-${index}-1`} className={`custom-td`}>
                        {new Date(item.date).toLocaleDateString(undefined, options as any)}
                      </td>
                      <td key={`cell--${item.email}-${index}-2`} className={`custom-td`}>
                        {item.email}
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        }
      </div>
    </div>
  );
}

export default Logs;

