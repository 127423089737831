/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
/* eslint-disable @typescript-eslint/no-explicit-any */

import { DisplayConfig, EnumerationsConfigMap, TeePeeContent } from "./directus";

const readSpreadSheet = (workbook, sheetName) => {
  const worksheet = workbook.getWorksheet(sheetName);
  const columns: Array<string> = [];
  const items: Array<any> = [];
  worksheet.eachRow((row, rowIndex) => {
    const item = {};
    row.eachCell((cell, cellIndex) => {
      let value = cell.value;
      if (value.formula)
      {
        value = value.result;
      }
      else if (value.richText)
      {
        value = value.richText.map(t => t.text).join(" ");
      } else if (value.text)
      {
        value = value.text;
      } else {
        value = value.toString();
      }
      if (rowIndex === 1)
      {
        columns.push(value);
      } else {
        item[columns[cellIndex-1]] = value;
      }
    });
    if (rowIndex > 1)
    {
      items.push(item);
    }
  });
  return {columns, items};
}

const getRandomHSL = () => { 
  return {
    h: 360 * Math.random(),
    s: (25 + 70 * Math.random()),
    l: (85 + 10 * Math.random()) 
  }
}
const hslToHex = (h, s, l) => {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = n => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

const getRandomHexColor = () => { 
  const hsl = getRandomHSL();
  return hslToHex(hsl.h, hsl.s, hsl.l);
}


const parseComposition = (item: TeePeeContent, displaySetting: DisplayConfig, nomDeLaTable: string, enumConfigurations: EnumerationsConfigMap): string => {
  if (!displaySetting.composition) {
    return '';
  }
  let composition = displaySetting.composition.slice().trim();
  if (composition.length === 0)
  {
    return '';
  }
  const tags = composition.match(/{{(.*?)}}*/ig);
  tags.forEach(tag => {
    const parameter = tag.slice().replace("{{", "").replace("}}", "").trim();
    let value = "";
    const parameters = parameter.split(".");
    let currentItem: any = item;
    parameters.forEach(p => {
      currentItem = currentItem ? currentItem[p] : undefined;
      if (p.toLocaleLowerCase().startsWith("rel") && currentItem && Object.keys(currentItem).length > 0) {
        currentItem = currentItem[Object.keys(currentItem)[0]];
      }
    })
    if (
      typeof currentItem !== 'object' &&
      !Array.isArray(currentItem) &&
      currentItem !== null &&
      currentItem !== undefined
    ) {
      value = `${currentItem}`;
    }
    
    //console.log(nomDeLaTable, enumConfigurations[nomDeLaTable], parameter, value)
    if (value && displaySetting.estUneDate)
    {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      };
      value = new Date(value).toLocaleDateString(undefined, options as any);
    } else if (value && 
      displaySetting.estUneEnumerationDeValeur && 
      enumConfigurations[nomDeLaTable] && 
      enumConfigurations[nomDeLaTable][parameter] && 
      enumConfigurations[nomDeLaTable][parameter][value])
    {
      value = enumConfigurations[nomDeLaTable][parameter][value];
    }

    composition = composition.replace(tag, value);
  });
  return composition;
}

function setCookie(name,value,days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function getCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for(let i=0;i < ca.length;i++) {
    let c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

function eraseCookie(name) {   
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export { readSpreadSheet, getRandomHexColor, parseComposition, setCookie, getCookie, eraseCookie };