/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
import { Icon } from '@blueprintjs/core';
import React from 'react';

import { ActifTypesCarto, Asset } from '../../../shared/interfaces';

const AssetCard: React.FC<{
  asset: Asset;
  close: () => void
}> = ({
  asset,
  close
}) => {
  if (!asset) {
    return <></>
  }
  let outline = "border-blue-600";
  if (asset.StatutDeLActif === ActifTypesCarto.Perdu)
  {
    outline = "border-red-500";
  }
  if (asset.StatutDeLActif === ActifTypesCarto.HorsVinciLibre)
  {
    outline = "border-gray-800";
  }
  return (
    <div className={`text-lg w-full flex flex-col gap-1 p-4 rounded-xl bg-white border-2 ${outline} relative overflow-hidden`}>
      <div className='w-full flex flex-row gap-2'>
        <div className='font-bold whitespace-nowrap'>
          Statut:
        </div>
        <div className='flex-1 whitespace-nowrap'>
          {asset.StatutDeLActif}
        </div>
        {/*<Icon className="pointer-events-auto cursor-pointer" color='red' icon="small-cross" onClick={close}/>*/}
      </div>
      <div className='w-full flex flex-row gap-2'>
        <div className='font-bold whitespace-nowrap'>
          Nom commercial:
        </div>
        <div className='flex-1 whitespace-nowrap'>
          {asset.RelActifsCompletNomCommercialDuSite}
        </div>
      </div>
      <div className='w-full flex flex-row gap-2'>
        <div className='font-bold whitespace-nowrap'>
          Nature:
        </div>
        <div className='flex-1 whitespace-nowrap'>
          {asset.typeDActif}
        </div>
      </div>
      <div className='w-full flex flex-row gap-2'>
        <div className='font-bold whitespace-nowrap'>
          Client:
        </div>
        <div className='flex-1 whitespace-nowrap'>
          {asset.RELActifsCompletVERSClient}
        </div>
      </div>
      <div className='w-full flex flex-row gap-2'>
        <div className='font-bold whitespace-nowrap'>
          Surface:
        </div>
        <div className='flex-1 whitespace-nowrap'>
          {asset.surfaceTotale} m2
        </div>
      </div>
      {
        !asset.StatutDeLActif.includes("hors") &&
        <div className='w-full flex flex-row gap-2'>
          <div className='font-bold whitespace-nowrap'>
            Entreprise VF:
          </div>
          <div className='flex-1 whitespace-nowrap'>
            {asset.entrepriseVF}
          </div>
        </div>
      }
      <div className='w-full flex flex-row gap-2'>
        <div className='font-bold whitespace-nowrap'>
          Propriétaire:
        </div>
        <div className='flex-1 whitespace-nowrap'>
          {asset.RelActifsCompletVersProprieTaireActif}
        </div>
      </div>
      {
        asset.StatutDeLActif === ActifTypesCarto.Perdu &&
        <>
          <div className='w-full flex flex-row gap-2'>
            <div className='font-bold whitespace-nowrap'>
              Date de sortie:
            </div>
            <div className='flex-1 whitespace-nowrap'>
              
            </div>
          </div>
          <div className='w-full flex flex-row gap-2'>
            <div className='font-bold whitespace-nowrap'>
              Repreneur:
            </div>
            <div className='flex-1 whitespace-nowrap'>
              
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default AssetCard;