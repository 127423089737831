/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
import axios from 'axios';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { getCookie, getRandomHexColor } from '../../shared/helpers';
import { Config, RealEastateAsset } from '../../shared/interfaces';
import { useApplicationSettingsContext } from './application-settings-provider';

interface AssetsContextProps {
  assets: Array<RealEastateAsset>;
  saveAssets: (assets: Array<RealEastateAsset>) => void;
  config: Config;
  saveConfig: (config: Config) => void;
  assetsMap: Record<string,RealEastateAsset>;
}

const AssetsContext: React.Context<AssetsContextProps> =
  React.createContext<AssetsContextProps>(null);

const AssetsContextProvider: React.FC = props => {
  const {  token } = useApplicationSettingsContext();
  const [assets, setAssets] = React.useState<Array<RealEastateAsset>>([]);
  const [assetsMap, setAssetsMap] = React.useState<Record<string,RealEastateAsset>>({});
  const [config, setConfig] = React.useState<Config>({
    colors: {}
  });

  const loadAssets = async () => {
    const { data: loadedAssets } = await axios.get<Array<RealEastateAsset>>(`/api/assets`,{
      headers: {
        authorization: `Basic ${token??getCookie('lynx')}`
      }
    });
    const { data: loadedConfig } = await axios.get<Config>(`/api/config`,{
      headers: {
        authorization: `Basic ${token??getCookie('lynx')}`
      }
    });
    let assetDirections = loadedAssets.map(asset => asset.direction);
    assetDirections = [...new Set(assetDirections)];
    assetDirections.forEach(direction => {
      if (!loadedConfig.colors[direction]) {
        loadedConfig.colors[direction] = getRandomHexColor();
      }
    });
    const aMap = {};
    loadedAssets.forEach(a => {
      a.buildings = [];
      if (!a.uuid) {
        a.uuid = uuidv4()
      }
      aMap[a.uuid] = a;
    });
    setAssetsMap(aMap);
    setAssets(loadedAssets);
    setConfig(loadedConfig);
  }

  const saveAssets = async (updatedAssets: Array<RealEastateAsset>) => {
    await axios.post(`/api/assets`, updatedAssets,{
      headers: {
        authorization: `Basic ${token??getCookie('lynx')}`
      }
    });
    const aMap = {};
    updatedAssets.forEach(a => aMap[a.uuid] = a);
    setAssetsMap(aMap);
    setAssets(updatedAssets);
  }

  const saveConfig = async (updatedConfig: Config) => {
    await axios.post(`/api/config`, updatedConfig,{
      headers: {
        authorization: `Basic ${token??getCookie('lynx')}`
      }
    });
    setConfig(updatedConfig);
  }

  React.useEffect(() => {
    loadAssets();
  }, []);

  return (
    <AssetsContext.Provider
      value={{
        assets,
        assetsMap,
        saveAssets,
        config,
        saveConfig
      }}
      {...props}
    />
  );
};

const useAssetsContext = (): AssetsContextProps => {
  const context = React.useContext(AssetsContext);
  if (context === undefined) {
    throw new Error(
      `useAssetsContext must be used within a AssetsContextProvider`,
    );
  }
  return context;
};

export { AssetsContextProvider, useAssetsContext };
