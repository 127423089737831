/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/

import axios from 'axios';
import React from 'react';

import { ProspectionDataSet } from '../../server/controller/business-logic';
import { getCookie } from '../../shared/helpers';
import LynxLayout from '../components/lynx-layout';
import { useApplicationSettingsContext } from '../contexts/application-settings-provider';
import { useDirectusContext } from '../contexts/directus-context';

const Prospection: React.FC<{
  textFilter: string
  setTextFilter: (value :string) => void
}> = ({
  textFilter,
  setTextFilter
}) => {
  const { token, refreshUserSettings } = useApplicationSettingsContext();
  const { applicationSettings } = useDirectusContext();

  React.useEffect(() => {
    refreshUserSettings();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const [dataSet, setDataSet] = React.useState<ProspectionDataSet>({
    tableDisplaySettings:  [],
    filtersDisplaySettings: [],
    enrichissements: []
  });
  const loadDataSet = async () => {
    const { data: loadedData } = await axios.get<ProspectionDataSet>(`/api/business/prospection`,{
      headers: {
        authorization: `Basic ${token??getCookie('lynx')}`
      }
    });
    //console.log("Prospection:", loadedData.filtersDisplaySettings.find(item => item.label === "Porteur de l’action commerciale"))
    const filtrePorteur = loadedData.filtersDisplaySettings.find(item => item.label === "Porteur de l’action commerciale");
    if (filtrePorteur) {
      filtrePorteur.aUneOptionVide = true;
    }
    setDataSet(loadedData);
  };

  React.useEffect(() => {
    loadDataSet()
  }, [])

  return (
    <LynxLayout 
      textFilter={textFilter}
      setTextFilter={setTextFilter}
      tableDisplaySettings={dataSet.tableDisplaySettings}
      filtersDisplaySettings={dataSet.filtersDisplaySettings}
      rawData={dataSet.enrichissements}
      teePeeBaseURL={applicationSettings.lienVersLesEnrichissements}
      customCellRenderer={{
        "Statut du formulaire" : (cellValue) => {
          const cellColor = cellValue.includes("Incomplet") ? "bg-lynx-high" : "bg-lynx-low";
          return <div className={`w-full text-white ${cellColor} rounded p-2 flex flex-row items-center justify-center`}>{cellValue}</div>
        }
      }}
    />
  );
}

export default Prospection;