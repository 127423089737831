/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
import 'mapbox-gl/dist/mapbox-gl.css';

import React from 'react';
import { useNavigate  } from 'react-router-dom';

import { useApplicationSettingsContext } from '../contexts/application-settings-provider';


const LandingPage: React.FC = () => {
  const { applicationSettings, cartoOnly, refreshUserSettings } = useApplicationSettingsContext();
  const navigate = useNavigate();

  const navigateTo = (target: string) => {
    if (target.startsWith("http"))
    {
      window.open(target);
    } else {
      navigate(target);
    }
  }

  React.useEffect(() => {
    refreshUserSettings();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <div className="w-full h-full flex flex-col gap-2 sm:p-4 md:p-12 items-center justify-center" style={{
      position: "fixed",
      bottom:0,
      top:0,
      left:0, 
      right:0, 
      width: "100%",
      height: "100dvh",
    }}>
      <div className='absolute p-4 hidden md:visible' style={{
        bottom: 0,
        right: 0
      }}>
        Version {process.env.VERSION}
      </div>
      <div className='w-full h-full lynx-background overflow-hidden hidden md:block'>
        <div className='w-full h-full flex flex-col gap-2 items-center justify-center lynx-backdrop-filter overflow-hidden'>
          <div className='w-1/2 h-1/2 flex flex-row gap-4 ' style={{
            minHeight: "300px",
            minWidth: "650px"
          }}>
            <div className='h-full'>
              <img className="block max-w-full max-h-full w-auto h-auto" src="/assets/img/logos/lynx.png" />
            </div>
            <div className='flex-1 h-full flex flex-col items-center overflow-hidden bg-white p-8'>
              { !cartoOnly && 
                <>
                  <div className='w-full flex-1 p-2 flex flex-row items-center justify-center'>
                    <button className={"hover:opacity-90 bg-lynx-taguage w-full h-16 text-xl rounded-xl font-bold flex flex-row items-center justify-center"} onClick={async () => {
                      navigateTo(applicationSettings.landingPageLinkURLA);
                    }}>
                      {applicationSettings?.landingPageLinkLabelA}
                    </button>
                  </div>
                  <div className='w-full flex-1 p-2 flex flex-row items-center justify-center'>
                    <button className={"hover:opacity-90 bg-lynx-prospection w-full h-16 text-xl rounded-xl font-bold flex flex-row items-center justify-center"} onClick={async () => {
                      navigateTo(applicationSettings.landingPageLinkURLB);
                    }}>
                      {applicationSettings?.landingPageLinkLabelB}
                    </button>
                  </div>
                </>
              }
              <div className='w-full flex-1 p-2 flex flex-row items-center justify-center'>
                <button className={"hover:opacity-90 bg-lynx-carto w-full h-16 text-xl rounded-xl font-bold flex flex-row items-center justify-center"} onClick={async () => {
                  navigateTo(applicationSettings.landingPageLinkURLC);
                }}>
                  {applicationSettings?.landingPageLinkLabelC}
                </button>
              </div>
              { !cartoOnly && 
                <div className='w-full flex-1 p-2 flex flex-row items-center justify-center'>
                  <button className={"hover:opacity-90 bg-lynx-bdd w-full h-16 text-xl rounded-xl font-bold flex flex-row items-center justify-center"} onClick={async () => {
                    navigateTo(applicationSettings.landingPageLinkURLD);
                  }}>
                    {applicationSettings?.landingPageLinkLabelD}
                  </button>
                </div>
              }
              <div className='w-full flex-1 p-2 flex flex-row items-center justify-center'>
                <button className={"hover:opacity-90 bg-lynx-video w-full h-16 text-xl rounded-xl font-bold flex flex-row items-center justify-center"} onClick={async () => {
                  navigateTo(applicationSettings.landingPageLinkURLE);
                }}>
                  {applicationSettings?.landingPageLinkLabelE}
                </button>
              </div>
            </div>
          </div>
          </div>
      </div>
      <div className='w-full h-full overflow-hidden md:hidden'>
        <div className='w-full h-full flex flex-col gap-2 items-center justify-center lynx-backdrop-filter overflow-hidden'>
          <div className='w-full' style={{
            height: `calc(100% - 260px)`
          }}>
            <img className="w-full h-full object-contain" src="/assets/img/logos/lynx.png" />
          </div>
          <div className='w-full flex flex-col items-center overflow-hidden bg-white w-full' style={{
            height: `260px`
          }}>
            { !cartoOnly && 
              <>
                <div className='w-full flex-1 p-2 flex flex-row items-center justify-center'>
                  <button className={"hover:opacity-90 bg-lynx-taguage w-full h-16 text-xl rounded-xl font-bold flex flex-row items-center justify-center"} onClick={async () => {
                    navigateTo(applicationSettings.landingPageLinkURLA);
                  }}>
                    {applicationSettings?.landingPageLinkLabelA}
                  </button>
                </div>
                <div className='w-full flex-1 p-2 flex flex-row items-center justify-center'>
                  <button className={"hover:opacity-90 bg-lynx-prospection w-full h-16 text-xl rounded-xl font-bold flex flex-row items-center justify-center"} onClick={async () => {
                    navigateTo(applicationSettings.landingPageLinkURLB);
                  }}>
                    {applicationSettings?.landingPageLinkLabelB}
                  </button>
                </div>
              </>
            }
            <div className='w-full flex-1 p-2 flex flex-row items-center justify-center'>
              <button className={"hover:opacity-90 bg-lynx-carto w-full h-16 text-xl rounded-xl font-bold flex flex-row items-center justify-center"} onClick={async () => {
                navigateTo(applicationSettings.landingPageLinkURLC);
              }}>
                {applicationSettings?.landingPageLinkLabelC}
              </button>
            </div>
            { !cartoOnly && 
              <div className='w-full flex-1 p-2 flex flex-row items-center justify-center'>
                <button className={"hover:opacity-90 bg-lynx-bdd w-full h-16 text-xl rounded-xl font-bold flex flex-row items-center justify-center"} onClick={async () => {
                  navigateTo(applicationSettings.landingPageLinkURLD);
                }}>
                  {applicationSettings?.landingPageLinkLabelD}
                </button>
              </div>
            }
            <div className='w-full flex-1 p-2 flex flex-row items-center justify-center'>
              <button className={"hover:opacity-90 bg-lynx-video w-full h-16 text-xl rounded-xl font-bold flex flex-row items-center justify-center"} onClick={async () => {
                navigateTo(applicationSettings.landingPageLinkURLE);
              }}>
                {applicationSettings?.landingPageLinkLabelE}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default LandingPage;