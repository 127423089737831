/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
import axios from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { getCookie } from '../../shared/helpers';
import { useApplicationSettingsContext } from './application-settings-provider';

interface ProtectedRouteContextProps {
  isValid: boolean
}

const ProtectedRouteContext: React.Context<ProtectedRouteContextProps> =
  React.createContext<ProtectedRouteContextProps>(null);

const ProtectedRouteContextProvider: React.FC = props => {
  const { token } = useApplicationSettingsContext()
  const navigate = useNavigate();
  const [ isValid, setIsValid ] = React.useState<boolean>(false);

  async function Check(token: string) {
    try {
      const { data: result } = await axios.get<boolean>(`/api/isValid`, {
        headers: {
          authorization: `Basic ${token??getCookie('lynx')}`
        }
      });
      setIsValid(result);
    } catch(err) {
      navigate("/")
    }
  }

  React.useEffect(() => {
    Check(token);
  }, [token]);

  return (
    <ProtectedRouteContext.Provider
      value={{isValid}}
      {...props}
    >
      {props.children}
    </ProtectedRouteContext.Provider>
  );
};

const useProtectedRouteContext = (): ProtectedRouteContextProps => {
  const context = React.useContext(ProtectedRouteContext);
  if (context === undefined) {
    throw new Error(
      `useProtectedRouteContext must be used within a ProtectedRouteContextProvider`,
    );
  }
  return context;
};

export { ProtectedRouteContextProvider, useProtectedRouteContext };

