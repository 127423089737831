/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
import React from 'react';
import { MultiSelect, Option } from 'react-multi-select-component';

const Select: React.FC<{
  label: string;
  values: Array<Option>
  seletected: Array<Option>
  onSelected: (selected: Array<Option>) => void
}> = ({
  label,
  values,
  seletected,
  onSelected
}) => {
  return (
    <div className='w-full flex flex-col gap-2'>
      <label
        className="block text-sm font-bold"
      >
        <div className="flex items-center">
          {label}
        </div>
      </label>
      <MultiSelect
        options={values??[]}
        className='w-full text-sm'
        value={seletected}
        onChange={onSelected}
        labelledBy={label}
        overrideStrings={{
          "allItemsAreSelected": "Tous selectionné.",
          "clearSearch": "Nettoyer la recherche",
          "clearSelected": "Déselectionner",
          "noOptions": "Pas d'otions correspondante",
          "search": "Filtrer..",
          "selectAll": "Sélectionner tout",
          "selectAllFiltered": "Sélectionner",
          "selectSomeItems": "Tous...",
          "create": "Create",
        }}
      />
    </div>
  )
}

export default Select;