/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
import 'mapbox-gl/dist/mapbox-gl.css';

import { Button,  FormGroup,  InputGroup,  Intent } from '@blueprintjs/core';
import React from 'react';
import { useNavigate  } from 'react-router-dom';

import { useApplicationSettingsContext } from '../contexts/application-settings-provider';

const Input: React.FC<{
  label: string;
  placeHolder: string
  seletected: any
  onSelected: (selected: any) => void,
  type: string
}> = ({
  label,
  placeHolder,
  seletected,
  onSelected,
  type
}) => {
  return (
    <div className='w-full flex flex-col gap-2'>
      <label
        className="block text-sm font-bold"
      >
        <div className="flex items-center">
          {label}
        </div>
      </label>
      <input
        className="w-full border rounded text-sm p-2"
        placeholder={placeHolder}
        value={seletected}
        onChange={(e) => {
          onSelected(e.target.value)
        }}
        type={type}
      />
    </div>
  )
}

const Login: React.FC = () => {
  const { login } = useApplicationSettingsContext();
  const [ email, setEmail ] = React.useState<string>();
  const [ password, setPassword ] = React.useState<string>();
  const [ message, setMessage ] = React.useState<string>("");
  const navigate = useNavigate();
  
  return (
    <div className="w-full h-full flex flex-col gap-2 sm:p-4 md:p-12 items-center justify-center">
      <div className='absolute p-4' style={{
        bottom: 0,
        right: 0
      }}>
        Version {process.env.VERSION}
      </div>
      <div className='w-full h-full lynx-background overflow-hidden'>
        <div className='w-full h-full flex flex-col gap-2 items-center justify-center lynx-backdrop-filter overflow-hidden'>
          <div className='w-1/2 h-1/2 flex flex-row gap-4 ' style={{
            minHeight: "320px",
            minWidth: "700px"
          }}>
            <div className='h-full'>
              <img className="block max-w-full max-h-full w-auto h-auto" src="/assets/img/logos/lynx.png" />
            </div>
            <div className='flex-1 h-full flex flex-col items-center overflow-hidden bg-white p-8'>
              <div className='w-full flex-1 p-2 font-bold text-2xl'>Connexion</div>
              <div className='w-full flex-1 p-2 flex flex-col gap-2'>
                <FormGroup
                  label={<label className='font-bold'>Email</label>}
                  labelFor={`email-input`}
                  className='w-full'
                >
                  <InputGroup className="bg-white" id={`email-input`} placeholder="prenom.nom@domain.fr" fill value={email} onChange={
                    e => {
                      setEmail(e.target.value);
                    }
                  }/>
                </FormGroup>
              </div>
              <div className='w-full flex-1 p-2 flex flex-col gap-2'>
                <FormGroup
                  label={<label className='font-bold'>Mot de passe</label>}
                  labelFor={`password-input`}
                  className='w-full'
                >
                  <InputGroup className="bg-white" id={`password-input`} fill value={password} type="password" onChange={
                    e => {
                      setPassword(e.target.value);
                    }
                  }/>
                </FormGroup>
              </div>
              <div className='w-full flex-1 p-2 flex flex-row items-center justify-center'>
                <button className={"hover:opacity-90 bg-lynx-info w-full h-16 text-xl rounded-xl font-bold flex flex-row items-center justify-center"} onClick={async () => {
                  const success = await login(email, password);
                  if (!success)
                  {
                    setMessage("Erreur d'authification")
                  } else {
                    console.log("/menu")
                    navigate("/menu")
                  }
                }}>
                  Se connecter
                </button>
              </div>
              {
                message && 
                <div className='w-full flex-1 p-2 flex flex-row items-center justify-center h-12 min-h-12 text-red-500'>
                  {message}
                </div>
              }
              {
                !message && 
                <div className='w-full flex-1 p-2 flex flex-row items-center justify-center h-12 min-h-12'>
                  Veuillez vous authentifier...
                </div>
              }
            </div>
          </div>
          </div>
      </div>
    </div>
  );
}

export default Login;