/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
import { Button, Intent  } from '@blueprintjs/core';
import React from 'react';
import { HexColorPicker } from "react-colorful";
import { useNavigate } from 'react-router-dom';

import { useDirectusContext } from '../../contexts/directus-context';
 
const ColorSettings: React.FC = () => {
  const { getColors, saveColors } = useDirectusContext();
  const [colors, setColors] = React.useState<Record<string, string>>({});
  const navigate = useNavigate();

  const [, updateState] = React.useState<unknown>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  React.useEffect(() => { 
    setColors(getColors)
  }, [getColors])
  
  return (
    <div className="w-full h-full flex flex-col gap-4 p-4 overflow-auto">
      <div className='flex-1 w-full flex flex-wrap justify-center gap-2'>
        {
          Object.keys(colors).map(key => {
            return (
              <div key={key} className="flex flex-col gap-2 items-center justify-center p-4">
                <HexColorPicker color={colors[key]??"#ffffff"} onChange={(c => {
                  colors[key] = c;
                  while (colors[key].length < '#ffffff'.length)
                  {
                    colors[key] = `#0${colors[key].slice(1)}`;
                  }
                  forceUpdate();
                })} />
                <input
                  className="w-full border rounded p-2"
                  value={colors[key]??"#ffffff"}
                  onChange={(e) => {
                    colors[key] = e.target.value;
                    forceUpdate();
                  }}
                />
                <div className='font-bold'>{key}</div>
              </div>
            )
          })
        }
      </div>
      <div className='h-10 flex flex-row items-center justify-end gap-2'>
        <Button text="Sauvegarder" intent={Intent.PRIMARY} onClick={() => {
          Object.keys(colors).forEach(key => {
            while (colors[key].length < '#ffffff'.length)
              {
                colors[key] = `#0${colors[key].slice(1)}`;
              }
          })
          saveColors(colors);
          navigate("/lynx?page=carto");
        }}/>
        <Button text="Annuler" intent={Intent.DANGER} onClick={() => {
          navigate("/lynx?page=carto");
        }}/>
      </div>
      
    </div>
  );
}

export default ColorSettings;

