/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Icon, Intent, NonIdealState, Spinner } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import axios from 'axios';
import React from 'react';

import { Asset, LynxDocument } from '../../shared/interfaces';
import { useApplicationSettingsContext } from '../contexts/application-settings-provider';

const Documents: React.FC<{
  assetId: string
  asset: Asset
  onClose: () => void
  documents: Array<LynxDocument>
  loadDocuments: () => Promise<void>
  importing: boolean
  setImporting: (val: boolean) => void
}> = ({
  assetId,
  asset,
  onClose,
  documents,
  loadDocuments,
  importing,
  setImporting
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const {nom, prenom} = useApplicationSettingsContext();
  const [from, setFrom] = React.useState<Date>(new Date(Date.now()));
  const [filesToUpload, setFilesToUpload] = React.useState<Array<File>>([]);
  const  [displayDoc, setDisplayDoc ] = React.useState<LynxDocument>(undefined);
  const  [displayUpload, setDisplayUpload] = React.useState<boolean>(false);

  const onChangedFiles = async (files: FileList | null) => {
    const result: Array<File> = [];
    if (!files) {
      setFilesToUpload(result);
      return;
    }
    for(let i = 0; i < files.length; i++){
      const f = files[i];
      result.push(f)
    }
    setFilesToUpload(result);
    setDisplayUpload(true);
  }

  const documentsPerDate = React.useMemo(() => {
    const result: Record<string, Array<LynxDocument>> = {};
    documents.forEach(item => {
      const refDate = new Date(item.date);
      const year = `${refDate.getFullYear()}`;
      const month = (refDate.getMonth() + 1) < 10 ? `0${refDate.getMonth()}` : `${refDate.getMonth()}`;
      const day = (refDate.getDate() + 1) < 10 ? `0${refDate.getDate()}` : `${refDate.getDate()}`;
      const finalRef = `${year}-${month}-${day}`;
      if (!result[finalRef]) {
        result[finalRef] = [];
      }
      result[finalRef].push(item)
    });
    Object.keys(result).forEach(key => result[key] = result[key].sort((a,b) => { return a.name.localeCompare(b.name)}))
    return result;
  }, [documents])

  if (importing) {
    return (
      <div className="w-full h-full flex flex-col gap-2 p-4 pointer-events-auto items-center justify-center">
        <Spinner intent={Intent.DANGER} size={200}/>
      </div>
    )
  }

  const siteName = asset ? asset.RelActifsCompletNomCommercialDuSite === "N/A" ? asset.adress : asset.RelActifsCompletNomCommercialDuSite : "Nom commercial du site";

  return (
    <div className="w-full h-full flex flex-col gap-2 p-4 pointer-events-auto">
      {
        <>
          { 
            !displayDoc &&
            <div className={`w-full flex flex-row gap-2 justify-end items-center`}>
              <img className="p-2 block max-w-full max-h-full w-auto h-auto text-lynx-vinci" src={`/assets/img/icons/buildings.svg`} />
              <div className='flex-1 font-bold text-lynx-vinci whitespace-nowrap overflow-ellipsis'>
                {siteName} - {documents.length} document(s)
              </div>
              <button className={"hover:opacity-90 bg-lynx-vinci text-white text-xl rounded h-12 font-bold flex flex-row items-center justify-center pl-4 pr-4 pb-2"} onClick={async () => {
                  inputRef.current.click();
                }}>
                  Importer des documents
                </button>
                <button className={"hover:opacity-90 bg-lynx-low text-white text-xl rounded h-12 font-bold flex flex-row items-center justify-center pl-4 pr-4 pb-2"} onClick={async () => {
                  onClose()
                }}>
                  Fermer
                </button>
            </div>
          }
          <div className={`w-full h-full flex-1 overflow-auto ${(displayUpload || displayDoc) ? "hidden" : ""}`}>
            <div className='flex-1 overflow-auto pr-2'>
              {
                documents.length === 0 &&
                <div className='w-full h-full flex flex-row items-center justify-center'>
                  <NonIdealState description="Aucun document !" icon="database"/>
                </div>
              }
              {
                documents.length > 0 &&
                Object.keys(documentsPerDate).sort().reverse().map(refDate => {
                  return (
                    <div className='w-full flex flex-col gap-2 pb-4' key={refDate}>
                      <div className='w-full font-xl p-2 text-bold text-white bg-lynx-vinci'>{refDate}</div>
                        <table
                          className={`pl-4 bp3-html-table bp3-html-table-striped bp3-interactive w-full`}
                        >
                          <thead>
                            <tr className='bg-lynx-vinci text-white overflow-hidden'>
                              <th key={`column-${0}`} className='border-l-2 border-white custom-th overflow-hidden text-white'>
                                Nom du document
                              </th>
                              <th key={`column-${0}`} className='border-l-2 border-white custom-th overflow-hidden text-white'>
                                Soumissionnaire
                              </th>
                              <th key={`column-${0}`} className='border-l-2 border-white custom-th overflow-hidden text-white'>
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              documentsPerDate[refDate].map((document, rowIndex) => {
                                return (
                                  <tr key={`item--${rowIndex}`}>
                                    <td  key={`cell-name`} className={`custom-td`}>
                                      {document.name}
                                    </td>
                                    <td  key={`cell-size`} className={`custom-td`}>
                                      {document.uploader}
                                    </td>
                                    <td  key={`cell-actions`} className={`custom-td flex flex-row gap-2 justify-end items-center `}>
                                      {
                                        document.name.toLocaleLowerCase().endsWith("pdf") &&
                                        <Icon icon="eye-open" className='cursor-pointer text-green-500 hover:text-yellow-500' onClick={() => {
                                          setDisplayDoc(document)
                                        }}/>
                                      }
                                      {
                                        <a href={`/content/${document.uuid}`} download={document.name}>
                                          <Icon icon="download" className='cursor-pointer text-lynx-vinci hover:text-yellow-500'/>
                                        </a>
                                      }
                                      <Icon icon="trash" className='cursor-pointer  text-red-500  hover:text-yellow-500' onClick={async () => {
                                        if (confirm('Cette opération est irréverssible. Supprimer ce fichier ?')) {
                                          await axios.delete(`/api/document/${document.uuid}`);
                                          loadDocuments();
                                        } else {
                                          // Do nothing!
                                          console.log('Do nothing.');
                                        }
                                      }}/>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                    </div>
                  )
                })
              }
              
            </div>
          </div>
          <div className={`w-full flex-1 overflow-hidden ${displayUpload ? "" : "hidden"}`}>
            <form className="w-full h-full flex flex-col gap-2 p-4" id="Form" action="/api/upload" encType="multipart/form-data" method="post" onSubmit={(event) => {
              event.preventDefault();
            }}>
              <input type="file" name="myfiles" multiple={true} ref={inputRef} onChange={event => {
                onChangedFiles(event.target.files);
              }} className="hidden"/>
              <div className='font-xl font-bold'>Veuillez spécifier la date de référence de ce jeu de documents:</div>
              <DateInput
                formatDate={date => {
                  const options = {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  };
                  return new Date(date).toLocaleDateString(undefined, options as any);
                }}
                onChange={(selectedDate) => {
                  if (!selectedDate) {
                    return;
                  }
                  setFrom(selectedDate);
                }}
                parseDate={str => new Date(str)}
                value={from}
                className='w-full'
              />
              <div className='font-xl font-bold'>Documents sélectionnés:</div>
              <div className='w-full flex-1 flex flex-col gap-2'>
                <div className='flex-1 overflow-auto '>
                  <table
                    className={`bp3-html-table bp3-html-table-striped bp3-interactive w-full`}
                  >
                    <thead>
                      <tr className='bg-lynx-vinci text-white overflow-hidden'>
                        <th key={`column-${0}`} className='border-l-2 border-white custom-th overflow-hidden text-white'>
                          Nom
                        </th>
                        <th key={`column-${0}`} className='border-l-2 border-white custom-th overflow-hidden text-white'>
                          Taille
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        filesToUpload.map((file, rowIndex) => {
                          return (
                            <tr key={`item--${rowIndex}`}>
                              <td  key={`cell-name`} className={`custom-td`}>
                                {file.name}
                              </td>
                              <td  key={`cell-size`} className={`custom-td`}>
                                {file.size}
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              
              <button className={"hover:opacity-90 bg-lynx-vinci text-white text-xl rounded h-12 font-bold flex flex-row items-center justify-center pl-4 pr-4 pb-2"} onClick={async () => {
                setImporting(true);
                setDisplayUpload(false);
                const target = document.getElementById("Form") as any;
                const url = "/api/upload";
                const request = new XMLHttpRequest();
                request.open('POST', url, true);
                request.onload = function() { // request successful
                // we can use server response to our request now
                  console.log("success");
                  console.log(request.responseText);
                  loadDocuments();
                };
              
                request.onerror = function() {
                  // request failed
                  console.log("onerror");
                  loadDocuments();
                };
              
                const formData = new FormData(target as any);
                formData.append("referenceDate", from.toISOString());
                formData.append("assetId", assetId);
                formData.append("nom", nom);
                formData.append("prenom", prenom);
                request.send(formData);
              }}>
                {`Lancer l'import`}
              </button>
            </form>
          </div>
          <div className={`w-full h-full flex-1 ${displayDoc ? "" : "hidden"} flex flex-col gap-2 overflow-auto `}>
            <div className={`w-full flex flex-row gap-2 justify-end items-center`}>
              <div className='font-bold flex-1'>
                {displayDoc?.name}
              </div>
              <button className={"hover:opacity-90 bg-lynx-low text-white text-xl rounded h-12 font-bold flex flex-row items-center justify-center pl-4 pr-4 pb-2"} onClick={async () => {
                setDisplayDoc(undefined);
              }}>
                Fermer le document
              </button>
            </div>
            <div className='flex-1 w-full overflow-hidden'>
              {displayDoc && <iframe
                  src={`/pdf/${displayDoc.uuid}#toolbar=0`}
                  width="100%"
                  height="100%"
                  loading="lazy"
                  title={displayDoc.name}
              ></iframe>}
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default Documents;