/*************************
 * Copyright (C) Mustapha BISMI - All Rights Reserved.
 *************************/

import { Intent, Spinner } from '@blueprintjs/core';
import React from 'react';

const PageLoader: React.FC = () => {
  
  return (
    <>
      <div className="w-full h-full hidden md:block">
        <div className="w-full h-full flex flex-col gap-2 p-12 items-center justify-center">
          <div className='w-full h-full lynx-background overflow-hidden'>
            <div className='w-full h-full flex flex-col gap-2 items-center justify-center lynx-backdrop-filter overflow-hidden'>
              <div className='w-1/2 h-1/2 flex flex-row gap-4 '>
                <div className='h-full'>
                  <img className="block max-w-full max-h-full w-auto h-auto" src="/assets/img/logos/lynx.png" />
                </div>
                <div className='flex-1 h-full flex flex-col items-center justify-center overflow-hidden bg-white p-8'>
                  <Spinner intent={Intent.DANGER} size={200}/>
                </div>
              </div>
              </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full md:hidden">
        <div className="w-full h-full flex flex-col gap-2 items-center justify-center">
          <div className='flex-1 h-full flex flex-col items-center justify-center overflow-hidden bg-white p-8'>
            <Spinner intent={Intent.DANGER} size={200}/>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageLoader;